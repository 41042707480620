<div class="book-extract-container">
    <div class="mat-dialog-actions" mat-dialog-actions [align]="selectedAlign">
        <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>
    <div class="title-author">
        <!--<h2>{{ material.author }}: {{ material.title }} - Betekintés</h2>-->
    </div>
    <div
        [ngStyle]="{
            'background-image': 'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(' + material.url + ')',
            'background-repeat': 'repeat',
            'background-size': '10%'
        }"
        class="book-container"
    ></div>
</div>
